<template>
  <div>
    <vx-card
      ><h3 class="mb-5 text-info">{{ $t("Detalles de la regla") }}</h3>
      <div v-if="rule && !loading" class="grid grid-cols-2 gap-5">
        <div class="w-full">
          <label for="rule_name">{{ $t("Nombre de la regla") }}:</label>
          <vs-input
            v-model="rule.name"
            id="rule_name"
            class="w-full"
            :disabled="true"
          />
        </div>
        <div>
          <label for="quantity_customers"
            >{{ $t("Cantidad por usuario") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="rule.quantity_customers"
            id="quantity_customers"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>

        <div>
          <label for="quantity_shopkeepers"
            >{{ $t("Cantidad de promociones") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="rule.quantity_shopkeepers"
            id="quantity_shopkeepers"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <div class="hidden">
          <label for="val_customer"
            >{{ $t("Valor máximo por consumidor") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="rule.val_customer"
            id="val_customer"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <div>
          <label for="promotion-type">{{ $t("Tipo de promoción") }}:</label>
          <VSelect
            id="promotion-type"
            :options="promotionTypes"
            :reduce="(product) => product.id"
            v-model="rule.type_promotions_id"
            class="w-full pointer-events-none"
            :disabled="true"
          >
          </VSelect>
        </div>
        <!-- PROMOCION DESCUENTOS -->
        <div v-if="rule.name_type_promotions === 'Descuentos'">
          <label for="percent_discount"
            >{{ $t("Porcentaje de descuento") }} (%):</label
          >
          <vs-input
            type="number"
            v-model.number="rule.percent_discount"
            id="percent_discount"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <!-- PROMOCION COMBO -->
        <div v-if="rule.name_type_promotions === 'Combo'">
          <label for="percent_discount"
            >{{ $t("Porcentaje de descuento") }} (%):</label
          >
          <vs-input
            type="number"
            v-model.number="rule.percent_discount"
            id="percent_discount"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <div v-if="rule.name_type_promotions === 'Combo'">
          <label for="promotion-type">{{ $t("Tipo de activación") }}:</label>
          <VSelect
            id="promotion-type"
            :options="activatetypes"
            :reduce="(product) => product.id"
            v-model="rule.activate"
            class="w-full"
            :disabled="true"
          >
          </VSelect>
        </div>
        <div
          v-if="
            rule.name_type_promotions === 'Combo' && rule.activate === 'value'
          "
        >
          <label for="combo_value">{{ $t("Valor combo") }}:</label>
          <vs-input
            type="number"
            v-model.number="rule.combo_value"
            id="combo_value"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>

        <!-- PROMOCION BONIFICACION -->
        <div v-if="rule.name_type_promotions === 'Bonificacion'">
          <label for="rule_reward"
            >{{ $t("Producto de bonificar") }} (SKU):</label
          >
          <vs-input
            type="text"
            v-model="rule.reward"
            id="rule_reward"
            class="w-full"
            :disabled="true"
          />
        </div>
        <div v-if="rule.name_type_promotions === 'Bonificacion'">
          <label for="reward_quant">{{ $t("Cantidad a bonificar") }}:</label>
          <vs-input
            type="number"
            v-model.number="rule.reward_quant"
            id="reward_quant"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <div v-if="rule.name_type_promotions === 'Bonificacion'">
          <label for="rule_activate">{{ $t("Tipo de activación") }}:</label>
          <VSelect
            id="rule_activate"
            :options="activatetypes"
            :reduce="(product) => product.id"
            v-model="rule.activate"
            class="w-full"
            :disabled="true"
          >
          </VSelect>
        </div>
        <div
          v-if="
            rule.name_type_promotions === 'Bonificacion' &&
            rule.activate === 'value'
          "
        >
          <label for="activate_val">{{ $t("Valor para activación") }}:</label>
          <vs-input
            type="number"
            v-model.number="rule.activate_val"
            id="activate_val"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <div
          v-if="
            rule.name_type_promotions === 'Bonificacion' &&
            rule.activate === 'quantity'
          "
        >
          <label for="activate_quant"
            >{{ $t("Cantidad para activación") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="rule.activate_quant"
            id="activate_quant"
            class="w-full"
            min="0"
            :disabled="true"
          />
        </div>
        <!-- PROMOTION ESCALONADA -->
        <div v-if="rule.name_type_promotions === 'Escalonada'">
          <label for="rule_activate">{{ $t("Tipo de activación") }}:</label>
          <VSelect
            id="rule_activate"
            :options="activatetypes"
            :reduce="(product) => product.id"
            v-model="rule.activate"
            class="w-full"
            :disabled="true"
          >
          </VSelect>
        </div>
        <div
          v-if="rule.name_type_promotions === 'Escalonada' && rule.activate"
          class="my-2 col-span-full px-5 pt-6 pb-8 rounded-lg shadow-sm"
        >
          <div
            v-for="(item, index) in rule.activate_list"
            :key="index"
            class="flex items-end gap-4"
          >
            <div>
              <label
                >{{
                  rule.activate === "value" ? $t("Valor") : $t("Cantidad")
                }}:</label
              >
              <vs-input
                type="number"
                v-model.number="item[rule.activate]"
                class="w-full"
                min="0"
                :disabled="true"
              />
            </div>
            <div>
              <label for="percent_discount">{{ $t("Descuento") }} (%):</label>
              <vs-input
                type="number"
                v-model.number="item['percent_discount']"
                id="percent_discount"
                class="w-full"
                min="0"
                :disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="my-20 text-center">
        <feather-icon icon="RefreshCwIcon" class="animate-spin"></feather-icon>
      </div>

      <div class="mt-4 flex justify-end gap-5">
        <vs-button class="rounded-full" @click.native="$router.go(-1)">
          {{ $t("Volver") }}
        </vs-button>
      </div></vx-card
    >
  </div>
</template>
<script>
import VSelect from "vue-select";
import http from "@/http/promotions-http-client.js";
export default {
  name: "DetailRulePage",
  components: {
    VSelect,
  },
  data() {
    return {
      rule: null,
      loading: false,
      promotionTypes: [],
      activatetypes: [
        { label: "Cantidad", id: "quantity" },
        { label: "Valor", id: "value" },
      ],
    };
  },
  async created() {
    await Promise.allSettled([this.getPromotionTypes(), this.getRule()]);

    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Reglas"),
      subtitle: this.$t("Ver los detalles de una regla"),
    });
  },
  methods: {
    async getRule() {
      const id = this.$route.params.id || "";
      this.loading = true;
      const response = await http.get(`api/RulesPromotionsSh/${id}`);
      this.loading = false;
      if (!response || response.status !== 200) return;
      this.rule = {
        ...response.data,
        reward: response.data.reward ? response.data.reward.split("_")[1] : "",
      };
    },
    async getPromotionTypes() {
      const response = await http.get("api/TypesPromotionsSh");
      if (response.status !== 200) return;
      this.promotionTypes = response.data.map((option) => ({
        id: option.key,
        label: option.name,
      }));
    },
  },
};
</script>
